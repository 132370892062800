import Header from "./component/header";
import Profile from "./profile.jpg";

function App() {
  return (
      <>
          <Header />
          <div className={'w-full md:max-w-2xl lg:max-w-4xl xl:max-w-5xl 2xl:max-w-7xl mx-auto py-12  px-4 md:px-0'}>
              <div className={'grid md:grid-cols-3 gap-x-4'}>
                  <div className={'space-y-8 md:col-span-2'}>
                      <div className={'space-y-8'}>
                          <div className={'flex flex-col pt-8'}>
                              <div className={'space-y-1'}>
                                  <h1 className={'text-[#E4E4E4] text-2xl lg:text-3xl xl:text-5xl font-bold tracking-[4px]'}>Dimas
                                      NurHamzah</h1>
                                  <p className={'text-[#E4E4E4] text-base md:text-xl xl:text-2xl font-medium tracking-[4px]'}>Full Stack
                                      Developer</p>
                              </div>
                          </div>
                          <p className={'text-[#E6E6E6] text-sm md:text-base lg:text-xl font-normal leading-[32px]'}>Seorang pengembang
                              perangkat lunak yang berpengalaman dalam mengembangkan website dan aplikasi menggunakan
                              teknologi seperti React, Laravel, Node.js, dan Flutter. Teliti, cepat belajar, dan
                              memiliki kemampuan analisis yang kuat</p>
                      </div>

                      <div className={'md:flex md:space-x-20 space-y-4'}>
                          <a href={'cv.pdf'} className={'rounded-xl border border-[#584DDC] bg-[#584DDC] w-[254px] h-[53px] flex items-center justify-center'}>
                              <p className={'text-[#FFF] text-2xl font-bold leading-[32px]'}>View Full Resume</p>
                          </a>
                          <div className={'grid grid-cols-3 gap-x-11'}>
                              <a href={'https://github.com/DimasHamzah?tab=repositories'} target={"_blank"}>
                                  <svg xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="0 0 42 42"
                                       fill="none">
                                      <path
                                          d="M21 0C18.2422 0 15.5115 0.556667 12.9636 1.63822C10.4158 2.71977 8.10079 4.30502 6.15076 6.30346C2.21249 10.3395 0 15.8135 0 21.5214C0 31.0338 6.027 39.1043 14.364 41.9667C15.414 42.1388 15.75 41.4717 15.75 40.8906V37.2535C9.933 38.5448 8.694 34.3696 8.694 34.3696C7.728 31.8731 6.363 31.206 6.363 31.206C4.452 29.8717 6.51 29.9147 6.51 29.9147C8.61 30.0653 9.723 32.1314 9.723 32.1314C11.55 35.4026 14.637 34.4342 15.834 33.9177C16.023 32.5188 16.569 31.5718 17.157 31.0338C12.495 30.4958 7.602 28.6449 7.602 20.4453C7.602 18.0564 8.4 16.141 9.765 14.613C9.555 14.075 8.82 11.8368 9.975 8.93137C9.975 8.93137 11.739 8.35029 15.75 11.1265C17.409 10.6531 19.215 10.4163 21 10.4163C22.785 10.4163 24.591 10.6531 26.25 11.1265C30.261 8.35029 32.025 8.93137 32.025 8.93137C33.18 11.8368 32.445 14.075 32.235 14.613C33.6 16.141 34.398 18.0564 34.398 20.4453C34.398 28.6665 29.484 30.4743 24.801 31.0123C25.557 31.6794 26.25 32.9923 26.25 34.9937V40.8906C26.25 41.4717 26.586 42.1604 27.657 41.9667C35.994 39.0828 42 31.0338 42 21.5214C42 18.6951 41.4568 15.8966 40.4015 13.2855C39.3461 10.6744 37.7993 8.30191 35.8492 6.30346C33.8992 4.30502 31.5842 2.71977 29.0364 1.63822C26.4885 0.556667 23.7578 0 21 0Z"
                                          fill="#E3E3E3"/>
                                  </svg>
                              </a>
                              <a href={'www.linkedin.com/in/dimas-nurhamzah'} target={"_blank"}>
                                  <svg xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="0 0 42 42"
                                       fill="none">
                                      <path
                                          d="M12.6 35H5.60001V14H12.6V35ZM9.10141 11.2C7.16521 11.2 5.60001 9.63061 5.60001 7.69861C5.60001 5.76661 7.16801 4.20001 9.10141 4.20001C11.0306 4.20001 12.6 5.76941 12.6 7.69861C12.6 9.63061 11.0306 11.2 9.10141 11.2ZM37.8 35H31.0702V24.78C31.0702 22.3426 31.024 19.208 27.5716 19.208C24.0674 19.208 23.5284 21.8624 23.5284 24.6036V35H16.8V13.9846H23.2596V16.856H23.3506C24.2494 15.204 26.446 13.4624 29.722 13.4624C36.54 13.4624 37.8 17.815 37.8 23.4738V35Z"
                                          fill="#E3E3E3"/>
                                  </svg>
                              </a>
                          </div>
                      </div>
                  </div>
                  <div className={'py-4 md:py-0'}>
                      <img src={Profile} alt=""
                           className={'rounded-xl border-4 border-[#6054EF] cursor-pointer transform duration-1000 hover:rotate-[5deg]'}/>
                  </div>
              </div>
          </div>

      </>
  );
}

export default App;
