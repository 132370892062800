export default function Header() {
    return (
        <header
            className={'h-24 w-full md:max-w-2xl lg:max-w-4xl xl:max-w-5xl 2xl:max-w-7xl px-4 md:flex items-center justify-center mx-auto hidden'}>
            <ul className={'flex space-x-7 text-[#EFEFEF] text-xl font-normal'}>
                <li className={'cursor-pointer transform duration-300 hover:text-[#C8ACD6]'}>Home</li>
                <li className={'cursor-pointer transform duration-300 hover:text-[#C8ACD6]'}>Experience</li>
                <li className={'cursor-pointer transform duration-300 hover:text-[#C8ACD6]'}>Project</li>
                <li className={'cursor-pointer transform duration-300 hover:text-[#C8ACD6]'}>Education</li>
                <li className={'cursor-pointer transform duration-300 hover:text-[#C8ACD6]'}>Skill</li>
                <li className={'cursor-pointer transform duration-300 hover:text-[#C8ACD6]'}>Contact</li>
            </ul>
        </header>
    )
}